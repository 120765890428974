.app_toplevel
  background: black
  height: 100vh

.app
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)


.app_text
  color: white
  @media screen and (min-width: 1921px)
    font-size: 300px
  @media screen and (max-width: 1920px)
    font-size: 150px
  @media screen and (max-width: 1366px)
    font-size: 100px
  @media screen and (max-width: 1068px)
    font-size: 75px
  @media screen and (max-width: 768px)
    font-size: 50px
  @media screen and (max-width: 400px)
    font-size: 25px
